import Head from 'next/head';
import { NotFound } from '@/components';

export default function Page() {
  // Opinionated: do not record an exception in Sentry for 404
  return (
    <>
      <Head>
        <title>404 | RankingMaster管理画面</title>
      </Head>

      <NotFound />
    </>
  );
}
